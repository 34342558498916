//
import GUserAccountService from '@/services/api/General/GUserAccount'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-auth-recovery',
  data() {
    return {
      busy: false,
      gUserAccount: {},
    }
  },
  methods: {

    /**
     * 
     */
    handleFormReset() {
      this.gUserAccount = GUserAccountService.shape({
        email: this.$route.query.email,
        password: '',
        repeat_password: '',
        token: this.$route.params.token,
      })
    },

    /**
     * 
     */
    async handleFormSubmit() {
      this.busy = true
      await GUserAccountService.resetPassword(this.gUserAccount).then(this.handleResetPasswordSuccess).catch(this.handleResetPasswordError)
      this.busy = false
    },

    /**
     * 
     */
    handleResetPasswordError(payload) {
      GUserAccountService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleResetPasswordSuccess(payload) {
      GUserAccountService.translate({ response: payload }).then(this.$toast.success)
      this.$router.push('/auth')
    },

    /**
     * 
     */
    handleRepeatPasswordRule() {
      return this.gUserAccount.password === this.gUserAccount.repeat_password || 'Las contraseñas deben ser las mismas.'
    },
  },
  created() {
    this.handleFormReset()
  },
  props: {

    /**
     * 
     */
    passwordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },

    /**
     * 
     */
    repeatPasswordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
      ],
    },
  }
}